<template>
  <div id="dynamicQrCodeForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <v-text-field v-model="dynamicQrCode.uuid" label="ID" :required="true" />
      <v-text-field
        v-model="dynamicQrCode.staffId"
        label="Staff"
        :required="true"
      />
      <v-text-field
        v-model="dynamicQrCode.type"
        label="Type"
        :required="false"
      />
      <v-text-field
        v-model="dynamicQrCode.jsonData"
        label="Data"
        :required="false"
      />

      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getDynamicQrCode } from "@/services/dynamicQrCode";

import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      dynamicQrCode: {
        relations: {},
        uuid: "",
        staffId: "",
        type: "",
        jsonData: "",
      },
    };
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("dynamicQrCode", [
      "createDynamicQrCode",
      "updateDynamicQrCode",
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (this.id) {
        let dynamicQrCode = this.dynamicQrCode;
        delete dynamicQrCode.relations;

        this.updateDynamicQrCode(dynamicQrCode)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("DynamicQrCode was updated successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating DynamicQrCode, please try again later"
            );
          });
      } else {
        let dynamicQrCode = this.dynamicQrCode;
        delete dynamicQrCode.relations;

        this.createDynamicQrCode(dynamicQrCode)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("DynamicQrCode was created successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating DynamicQrCode, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.dynamicQrCode.id = this.id;
        getDynamicQrCode(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(this.dynamicQrCode, property)
            ) {
              this.dynamicQrCode[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {},
  },
  components: {},
};
</script>
